import request from '@/utils/request';

//微信扫码支付
export function wxPayQrcodePay(data) {
  return request({
    url : '/pay/wxpay/qrcodePay',
    method : 'post',
    data : data
  })
}

//支付订单查询
export function queryWxOrderStatus(data) {
  return request({
    url : '/pay/wxpay/orderQuery',
    method : 'post',
    data : data
  })
}

//关闭订单
export function closeWxOrderStatus(data) {
  return request({
    url : '/pay/wxpay/closeOrder',
    method : 'post',
    data : data
  })
}

