import request from '@/utils/request';

//支付宝扫码支付
export function zfbPayQrcodePay(data) {
  return request({
    url : '/pay/alipay/pcPay',
    method : 'post',
    data : data
  })
}

//支付宝订单查询
export function queryAlipayOrderStatus(data) {
  return request({
    url : '/pay/alipay/orderQuery',
    method : 'post',
    data : data
  })
}

//关闭订单
export function closeAlipayOrderStatus(data) {
  return request({
    url : '/pay/alipay/closeOrder',
    method : 'post',
    data : data
  })
}
